import React, { Component } from 'react'
import Layout from '../components/layout/layout'
import Services from '../components/Services/Services'
import Hero from '../components/Hero'
export default class ServicesPage extends Component {
  render() {
    const heroImage = require('../assets/images/hello-i-m-nik-281498-unsplash.jpg')
    return (
      <Layout location={this.props.location}>
        <div className="gdlr-core-page-builder-body">
          <Hero
            title="Our Services"
            heroImage={heroImage}
            description="Have a look at some of our offerings"
            withOpacity
          />
          <Services showOtherServices />
        </div>
      </Layout>
    )
  }
}
