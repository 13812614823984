import React, { Component } from 'react'
import ServicesBlock from './ServicesBlock'
import ContactCTA from '../CTA/ContactCTA'

export default class Services extends Component {
  render() {
    return (
      <div>
        <ServicesBlock showOtherServices={this.props.showOtherServices} />
        <ContactCTA
          title={`Let's work`}
          description={`Whether you are an SME, Entrepreneur or established business, we make work`}
        />
      </div>
    )
  }
}
